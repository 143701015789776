import React from 'react';
import { Carousel } from 'react-bootstrap';
import './main_banner.css';

// Directly import the images
import image1 from '../../static/banner/banner1.png';
import image2 from '../../static/banner/banner2.png';
import image3 from '../../static/banner/banner3.png';
import image4 from '../../static/banner/banner4.png';

function MainBanner() {

    return (
        <Carousel>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={image1}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={image2}
                    alt="Second slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={image3}
                    alt="Third slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={image4}
                    alt="Fourth slide"
                />
            </Carousel.Item>
        </Carousel>
    );
}

export default MainBanner;
