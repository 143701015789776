import React from 'react';
import './conv_pred.css';
import { Link } from 'react-router-dom';

// Import the GIF directly from the static directory
import convGif from '../../static/convocatorias/conv.gif';

function ConvPred() {
    return (
        <>
            <div className="convnone">
                <h2>Convocatorias Cerradas</h2>
                <p>Actualmente no contamos con convocatorias abiertas, pero puedes registrarte en nuestras bases para recibir avisos.</p>

                <Link to="https://redmaestra.azurewebsites.net/api/formularios/agregar_comunidades/" className="btn btn-primary bl">Quiero estar informada</Link>
            </div>
            <img src={convGif} alt="convgif" className="n4"/>
        </>
    );
};

export default ConvPred;
